<template>
  <div class="registry-wrapper-top">
    <div class="heading">
      <h5>Refund Orders</h5>
    </div>
    <div class="buttons">
      <div class="form-group mb-0 mx-2" @keyup.enter="search($event)">
        <button class="search-icon" @click="search">
          <img src="../../assets/images/search.png" alt="Search" />
        </button>
        <input
          class="form-control"
          type="search"
          placeholder="Search Here..."
          aria-label="Search"
          v-model="filter.search"
          @keydown.enter="search"
          @input="preservePlusSign"
          title="You can search by Order Number, Customer ID, Email & by Refund ID."
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RefundTop",
  props: {
    count: Number,
    searched: String,
  },
  data() {
    return {
      filter: {
        search: "",
      },
    };
  },
  watch: {
    'filter.search'(newVal) {
      // Check if the search field is cleared
      if (newVal === "") {
        this.refreshListing(); // Call the function to refresh the listing
      }
    },
  },
  methods: {
    search() {
      const sanitizedSearch = this.filter.search.replace('+', '');
      console.log(sanitizedSearch);
      this.$root.$emit("searchForRefundOrders", { search: sanitizedSearch });
    },
    refreshListing() {
      // Logic to refresh the list of orders
      this.$root.$emit("searchForRefundOrders", { search: "" });
    },
    preservePlusSign(event) {
      // Keep the input clean of spaces
      this.filter.search = event.target.value.replace(/\s+/g, '');
    },
  },
};
</script>

<style>
/* Add your styles here */
</style>

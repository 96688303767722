<template>
  <div class="container-fluid filter-content">
    <div class="row no-gutters p-0 px-1 mb-2 justify-content-between">
      <div class="col-12 d-flex">
        <h5 class="mb-md-1">Filters</h5>
        <span class="toggle float-right px-2 cur-pointer" @click="toggleVisible">
          <i class="fa fa-angle-up" aria-hidden="true" v-if="visible"></i>
          <i class="fa fa-angle-down" aria-hidden="true" v-else></i>
        </span>
      </div>
    </div>

    <div v-if="visible">
      <div class="row">
        <div class="col-12 col-md-4 p-0 pr-2">
          <div class="form-group">
            <div class="date-pick">
              <input
                class="h-100"
                type="number"
                placeholder="Customer Id"
                v-model.number="filter.customer_id"
                @keyup.enter="applyFilter"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 p-0 pr-2">
          <div class="form-group">
            <div class="date-pick">
              <input
                class="h-100"
                placeholder="Customer Name"
                v-model="filter.name"
                @keyup.enter="applyFilter"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 p-0 pr-2">
          <div class="form-group">
            <div class="date-pick">
              <input
                class="h-100"
                placeholder="Phone"
                v-model="filter.phone"
                @keyup.enter="applyFilter"
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-4 p-0 pr-2">
          <div class="form-group">
            <div class="date-pick">
              <input
                class="h-100"
                placeholder="Email"
                v-model="filter.email"
                @keyup.enter="applyFilter"
              />
            </div>
          </div>
        </div>

        <div class="col-12 d-flex align-items-center col-md-4 p-0 pr-2">
          <button class="custom-btn filter-btn m-0" @click="applyFilter">
            Apply Filter
          </button>
          <button class="custom-btn filter-btn m-0 ml-4" @click="clearFilter">
            Reset Filter
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomersFilter",
  data() {
    return {
      visible: false,
      filter: {
        customer_id: null,
        name: "",
        phone: "",
        email: "",
        state: "",
      },
    };
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
      this.$emit("showChange", this.visible);
    },
    clearFilter() {
      // Reset the filter object to its initial state
      this.filter = {
        customer_id: null,
        name: "",
        phone: "",
        email: "",
        state: "",
      };
      // Emit the cleared filter to the parent component
      this.$emit("clearFilter", { ...this.filter });
    },
    applyFilter() {
      // Check if any filter field is filled before emitting
      if (
        this.filter.customer_id !== null ||
        this.filter.name !== "" ||
        this.filter.phone !== "" ||
        this.filter.email !== "" ||
        this.filter.state !== ""
      ) {
        this.$emit("clearFilter", { ...this.filter });
      } else {
        console.log("No filter applied.");
      }
    },
  },
  mounted() {
    const permissions = localStorage.getItem("permissions");
    this.isAdmin = [1, 5, 8].includes(parseInt(permissions));
    this.isVerfier = parseInt(permissions) === 4;
  },
};
</script>

  
  <style scoped>
  .form-group {
    margin-bottom: 0.5em;
  }
  .date-pick {
    height: 45px;
    width: 100%;
    border: 1px solid var(--light-gray) !important;
    padding-top: auto;
    border-radius: 0.25rem;
    position: relative;
  }
  .date-pick input
  {
    width: 100%;
    border: none;
    padding: 5px;
}
 
  .i {
    height: 20px;
    position: absolute;
    right: 0;
    padding-right: 3px;
  }
  .custom-select {
    border: 1px solid var(--light-gray) !important;
    border-radius: 0.25rem;
  }
  
  /* Custom Css for check inputs start */
  /* .dis{
    display: contents!important;
  } */
  label {
    display: flex;
    align-items: center;
    /* color: #9e9e9e; */
    position: relative;
  }
  
  label > span {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    border: 2px solid #9e9e9e;
    margin-right: 10px;
    border-radius: 3px;
    transition: all 0.3s;
    cursor: pointer;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input:checked + label > span {
    border: 7px solid #ffee00;
    animation: bounce 250ms;
  }
  input:checked + label > span::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 4px;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checked-box 125ms 250ms forwards;
  }
  @keyframes checked-box {
    0% {
      width: 0;
      height: 0;
      border-color: #212121;
      transform: translate(0, 0) rotate(45deg);
    }
    33% {
      width: 4px;
      height: 0;
      border-color: #212121;
      transform: translate(0, 0) rotate(45deg);
    }
    100% {
      margin-top: 6px;
      width: 4px;
      height: 8px;
      border-color: #212121;
      transform: translate(0, -8px) rotate(45deg);
    }
  }
  @keyframes bounce {
    0% {
      transform: scale(1);
    }
    33% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1);
    }
  }
  /* Custom Css for check inputs end */
  </style>
  
<template>
  <div class="registry-wrapper-top">
    <div class="heading">
      <h5>Customers</h5>
    </div>
    <div class="buttons">
      <div class="form-group mb-0 mx-2" @keyup.enter="search($event)">
        <button class="search-icon" @click="search($event)">
          <img src="../../assets/images/search.png" alt="" />
        </button>
        <input
          class="form-control"
          type="search"
          placeholder="Search Here..."
          aria-label="Search"
          v-model="filter.search"
          @keydown.enter="search($event)"
          title="You can search by Customer ID, Name, Phone, & by Email"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomersTop",
  props: {
    count: Number,
    searched: String,
  },
  data() {
    return {
      filter: {
        search: "",
      },
    };
  },
  watch: {
    'filter.search'(newVal) {
      if (newVal === "") {
        this.refreshListing(); // Call this method to refresh the listing when the search input is cleared
      }
    },
  },
  methods: {
    search() {
      // Remove the '+' sign from the search term
      const sanitizedSearch = this.filter.search.replace('+', '');
      console.log(sanitizedSearch);
      // Emit the sanitized search term
      this.$root.$emit("searchForCustomer", { search: sanitizedSearch });
    },
    refreshListing() {
      console.log('Listing refreshed');
      this.$root.$emit("searchForCustomer", { search: "" });
    },
    preservePlusSign(event) {
      // Keep the input clean of spaces
      this.filter.search = event.target.value.replace(/\s+/g, '');
    },
  },
};
</script>

<style>
/* Add your styles here */
</style>

<template>
    <div class="registry-wrapper-bottom">
      <h6>{{ count }} {{ count === 1 ? 'Customer' : 'Customers' }}</h6>
      <div class="bottom-right">
        <nav aria-label="Page navigation example">
          <ul v-if="page" class="pagination">
            <li
              :class="{ disabled: page.currentPage === 1 }"
              class="page-item previous-item"
            >
              <router-link
                :to="{ query: { page: page.currentPage - 1 } }"
                class="page-link"
                ><img src="../../assets/images/left.png" alt=""
              /></router-link>
            </li>
            <li
              v-for="page in page.totalPages"
              :key="page"
              :class="{ active: page.currentPage === page }"
              class="page-item"
            >
              <router-link :to="{ query: { page: page } }" class="page-link">{{
                page
              }}</router-link>
            </li>
            <li
              :class="{ disabled: page.currentPage === page.totalPages }"
              class="page-item next-item"
            >
              <router-link
              :to="{ query: { page: (page.currentPage * 2)/2 + 1 } }"
                class="page-link"
                ><img src="../../assets/images/right.png" alt=""
              /></router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomersBottom",
    props: {
      page: Object,
      count: Number,
    },
  
    data() {
      return {
        rowsFilter: false,
        filter: { pageSize: "" },
        sizeList: [
          { text: "100" },
          { text: "200" },
          { text: "300" },
          { text: "400" },
          { text: "500" },
        ],
        sizePlaceholder: "SHOW",
      };
    },
    watch: {
      $data: {
        handler: function() {
          if (this.filter.pageSize != null) {
            this.$root.$emit("filtersCallForRegistry", this.filter);
          }
        },
        deep: true,
      },
    },
    methods: {
      openRowsFilter: function() {
        this.rowsFilter = !this.rowsFilter;
      },
      rowsFilterOutside: function() {
        this.rowsFilter = false;
      },
    },
  };
  </script>
  
  <style></style>
  
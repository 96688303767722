<template>
  <div class="table-container" v-bind:class="classObject">
    <table class="table table-md-responsive"
      v-if="refundOrder.length > 0"
      >
      <thead>
        <tr>
          <th>
            Order Number
          </th>
          <th class="text-center">Customer ID</th>
          <th class="text-center">Phone</th>

          <th class="text-center">
            Email
          </th>
          <th class="text-center">
            Refund ID
          </th>
          <th class="text-center">Refund Risk Level</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in refundOrder" :key="index">
        <td>
          <div class="company-details">
            <h6 class="company-name">{{ item?.order?.order_number || 'N/A' }}</h6>
          </div>
        </td>
        <td class="text-center">{{ item.CustomerID || 'N/A' }}</td>
        <td class="text-center">{{ item.phone || 'N/A' }}</td>
        <td class="text-center">{{ item.email || 'N/A' }}</td>
        <td class="text-center">{{ item.RefundID || 'N/A' }}</td>
        <td class="text-center text-capitalize">{{ item.Risk_level || '-' }}</td>
      </tr>
      </tbody>
    </table>
    <div v-if="showLoader == 0 && refundOrder.length == 0" class="h-75 d-flex flex-column justify-content-center">
        <p class="no-registry">No refund order found.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTable",

  data() {
    return {};
  },
  props: {
    refundOrder: {
      type: Array,
    },
    message: Boolean,
    showLoader: Number,

  },
  methods: {
    capitalizeRiskLevel(riskLevel) {
      if (!riskLevel) return '';
      return riskLevel
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  },
  computed: {
    classObject: function () {
      return {
        'reg-table': this.message,
        'reg2-table': !this.message,
      }
    },
  },
  mounted() { },
};
</script>

<template>
  <div class="app-page">
    <Navbar />
    <div class="main-wrapper">
      <LeftMenu />
      <div class="container-fluid">
        <div class="row justify-content-end" v-if="isAdmin">
          <div class="Add">
            <button class="btn button" @click="createSubscriptionsPage">
              Add Subscription
            </button>
          </div>
        </div>

        <!-- Purchase Details Section -->
        <div v-if="purchases.length > 0" class="purchase-details-section">
          <hr>
          <h2 class="text-center">Purchase Details</h2>
          <hr>
          <div class="row">
            <div class="col-md-6 col-lg-4 p-2" v-for="purchase in purchases" :key="purchase.id">
              <div class="card card-purchase">
                <div class="card-header">
                  <h5>Plan Name: {{ purchases[0]?.subscriptionPlan?.name }}</h5>
                </div>
                <div class="card-body">
                  <!-- <p><strong>Date:</strong> {{ purchase.date }}</p> -->
                  <p><strong>Amount:</strong> ${{ purchase.amount }}</p>
                  <p><strong>Card:</strong> **** **** **** {{ purchase.card_last_four }}</p>
                  <p><strong>Remaining Transactions:</strong> {{ subscriptionDetail?.user?.transactions }}</p>
                  <p><strong>Per Transaction Rate:</strong> $ {{ purchases[0].subscriptionPlan?.perTransaction }}</p>
                  <!-- <p v-if="purchase.status === 1" class="text-success">Status: Successful</p>
                  <p v-else class="text-danger">Status: Failed</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loader and Subscription Plans Section -->
        <div v-if="showLoader == 1" class="lds-dual-ring-registry" id="loading"></div>

        <div v-if="subscriptionPlans.length > 0">
          <div>
            <hr>
            <h2 class="text-center">Our {{ packageLabel }}</h2>
            <hr>
          </div>
          <div class="row">
            <div class="col-md-6 col-lg-4 p-2 pricing card-deck flex-column flex-md-row"
              v-for="plan in subscriptionPlans" :key="plan.id">
              <div class="card card-pricing text-center px-3 mb-4">
                <span class="h6 w-60 mx-auto px-4 py-1 rounded-bottom lightSkyColor text-white shadow-sm">{{ plan.name
                  }}</span>
                <div class="bg-transparent card-header pt-4 border-0">
                  <h1 class="h1 font-weight-normal darkZincColor text-center mb-0" data-pricing-value="15">$<span
                      class="price">{{ plan.amount }}</span><span class="h6 text-muted ml-2">/ per {{ plan.transactions
                      }}</span></h1>
                </div>
                <div class="card-body pt-0">
                  <ul class="list-unstyled mb-4">
                    <li>{{ plan.description }}</li>
                    <li>Per Transaction: ${{ plan.perTransaction }}</li>
                  </ul>
                  <div>
                    <PaymentModal v-if="showAddPaymentModal" />
                    <button type="button" @click="payment(plan)" class="btn button custom-btn mb-3"
                      v-if="User && subscriptionDetail?.user?.subscription_status !== 1">
                      Add Subscription
                    </button>

                    <button type="button" @click="payment(plan)" class="button custom-btn mb-3"
                      v-if="User && subscriptionDetail?.user?.subscription_status === 1">
                      Update Subscription
                    </button>
                    <button type="button" @click="deleteSubscription(plan.id)" class="button custom-btn mb-3"
                      v-if="isAdmin">Remove Subscription</button>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!-- new card -->
          <!-- <div>
            <div class="row">
              <div class="col-md-6">
                  <div>
                    <div>
                      <p></p>
                    </div>
                  </div>
              </div>
            </div>
          </div> -->

        </div>

        <div v-if="showLoader == 0 && subscriptionPlans.length == 0"
          class="w-100 d-flex justify-content-center align-items-center">
          <p>There is no subscription added yet.</p>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import LeftMenu from "../components/leftmenu/LeftMenu.vue";
import Navbar from "../components/header/Navbar.vue";
import PaymentModal from "../components/payment/PaymentModal.vue";

export default {
  name: "Subscriptions",

  components: {
    Navbar,
    LeftMenu,
    PaymentModal,
  },

  data() {
      return {
        showForm: false,
        showAddPaymentModal: true,
        subscriptionPlans: [],
        subscriptionDetail: [],
        purchases: [], // Add purchases array
        User: false,
        isAdmin: false,
        showCardDetailModal: false,
        showLoader: 0,
      };
    },

  computed: {
    ...mapGetters("auth", ["user"]),
    packageLabel() {
      return this.subscriptionPlans.length === 1 ? "Package" : "Packages";
    },
  },

  methods: {
    payment(plan) {
      // Emit event to open PaymentModal11 with package info
      this.$root.$emit("openPaymentModal", {
        title: plan.name,
        amount: plan.amount,
        description: plan.description,
        transactions: plan.transactions,
        perTransaction: plan.perTransaction,
        package_id: plan.id
      });
    },
    // openPaymentModal() {
    //   this.showAddPaymentModal = true;
    // },
    deleteSubscription(id) {
      const subId = id;
      axios
        .delete(`${process.env.VUE_APP_API_URL}deleteSubscriptionPlan/${subId}`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.subscriptionPlans.forEach((item, index) => {
              if (item.id === subId) {
                this.subscriptionPlans.splice(index, 1);
                this.$swal("Subscription Deleted!", "Subcription Plan deleted Sucessfully.", "success");

              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to delete subscription plan.");
        });
    },
    createSubscriptionsPage() {
      this.$router.push("/createSubscriptions");
    },
    ...mapActions("auth", ["sendVerifyResendRequest"]),
    gettingValueChanged(value) {
      this.parentMessage = value;
    },

    fetchSubscriptionPlans() {
      this.showLoader = 1;
      axios
        .get(`${process.env.VUE_APP_API_URL}getAllSubscriptionPlans`)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.subscriptionPlans = response.data.data;
            this.showLoader = 0;
          }
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to get subscription plan.");
          this.showLoader = 0;
        });
    },

    fetchUserSubscriptionPlans() {
    this.showLoader = 1;
    const user_id = this.user && this.user.id ? this.user.id : localStorage.getItem('loginUserId');

    let formData = new FormData();
    formData.append('user_id', user_id);

    axios
      .post(`${process.env.VUE_APP_API_URL}getUserSubscriptionDetail`, formData)
      .then((response) => {
        if (response.data.statusCode === 200) {
          this.subscriptionDetail = response.data.data;
          this.purchases = response.data.data.purchases; // Add purchases to the array
        } else {
          console.log(response.data.message || "Failed to fetch subscription plans.");
        }
        this.showLoader = 0;
      })
      .catch((error) => {
        console.log("Failed to get subscription plan. Please try again later.", error);
        this.showLoader = 0;
      });
  },



  },
  watch: {},
  mounted() {
    //   if(this.user.user.role_id === 1){
    //   this.isAdmin=true
    //   this.fetchSubscriptionPlans();
    //   }
    //  if (localStorage.getItem("permissions") == 2) {
    //       this.User= true;
    //       this.fetchSubscriptionPlans();
    //   }
    this.$root.$on("openPaymentModal", () => {
      // this.openPaymentModal();
    });
    this.$root.$on("reNewSubs", () => {
      this.fetchUserSubscriptionPlans();
    });
    if (localStorage.getItem("permissions") == 2) {
      this.User = true;
      this.fetchSubscriptionPlans();
      this.fetchUserSubscriptionPlans();
    } else if (localStorage.getItem("permissions") == 1) {
      this.isAdmin = true;
      this.fetchSubscriptionPlans();
    }


  },
  created() { },
};
</script>

<style scoped>
.purchase-details-section {
  margin-bottom: 30px;
}

.card-purchase {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.card-purchase .card-header {
  background-color: #f8f9fa;
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.card-purchase .card-body {
  padding: 15px;
}

</style>
